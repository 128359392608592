/* eslint-disable react/no-danger */
import React from 'react';
import {
  makeStyles,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fieldRoot: {
    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  },
}));

function DynamicFormTextField({ formik, label, type, code, disabled, hintText, onChange, hidden }) {
  const classes = useStyles();
  const helperText = (
    <>
      {hintText}
      {hintText && formik.touched[code] && formik.errors[code] ? <br /> : null}
      {formik.touched[code] && formik.errors[code] && <span dangerouslySetInnerHTML={{ __html: formik.errors[code] }} />}
    </>
  );

  if (hidden) return null;

  return (
    <TextField
      className={classes.fieldRoot}
      fullWidth
      variant="outlined"
      id={code && code.toString()}
      name={code && code.toString()}
      type={type}
      label={label}
      value={formik.values[code]}
      rows={type === 'multilineText' ? 6 : null}
      multiline={type === 'multilineText'}
      onChange={(e) => {
        formik.handleChange(e);
        onChange && onChange(e);
      }}
      error={formik.touched[code] && Boolean(formik.errors[code])}
      helperText={helperText}
      disabled={disabled}
    />
  );
}

export default DynamicFormTextField;
